import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppGOTV = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="🗳️VOTE TODAY 🗳️"
      description="This election’s close. Join record numbers of voters. Let’s make history happen."
      shareImage="gotvge2019"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`👉 PASS IT ON 👉

👉 Send this on to 5 mates/groups 👉

👉 https://saveourfuture.uk/vote 👉

📅 PLAN YOUR DAY 📅
‣ Find your polling station: saveourfuture.uk/where
‣ What time can you go? (7am-10pm)
‣ How will you get there? (e.g. from work, home, uni)
‣ You don’t need your polling card or ID to vote

💪 TRIPLE YOUR VOTE 💪
‣ Know anyone not voting?
‣ Text them, team up, go vote together!`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppGOTV.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppGOTV;

export const query = graphql`
  query GOTVQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
